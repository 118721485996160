<template>
  <div class="block-why-we">
    <div class="block-why-we__marquees">
      <div class="block-why-we__marquee" ref="scrollText" :style="animatedScrollText">
        быстро эффективно автономно
      </div>
      <div class="block-why-we__marquee" :style="animatedScrollText">
        быстро эффективно автономно
      </div>
    </div>
    <div class="block-why-we__marquees--mobile">
      <div class="block-why-we__marquee" ref="scrollTextMobile" :style="animatedScrollText">
        быстро эффективно автономно 
      </div>
      <div class="block-why-we__marquee" :style="animatedScrollText">
        быстро эффективно автономно 
      </div>
    </div>
    <!-- <div class="block-why-we__marquees--mobile">
      <div class="block-why-we__marquee block-why-we__marquee--dark" ref="scrollTextMobile2" :style="animatedScrollText2">
        быстро эффективно автономно 
      </div>
    </div> -->
    <div class="block-why-we__rows">
      <div class="block-why-we__row">
        <div class="block-why-we__block">
          <div class="block-why-we__block-angle block-why-we__block-angle--left">
            <div class="block-why-we__block-angle-line"></div>
          </div>
          <div class="block-why-we__block-angle block-why-we__block-angle--right">
            <div class="block-why-we__block-angle-line"></div>
          </div>
          <div class="block-why-we__block-title">
            Быстрая интеграция в ваш проект
          </div>
          <div class="block-why-we__block-text">
            Пару кликов и AI-TALKER работает для вас
          </div>
        </div>
        <div class="block-why-we__block">
          <div class="block-why-we__block-angle block-why-we__block-angle--left">
            <div class="block-why-we__block-angle-line"></div>
          </div>
          <div class="block-why-we__block-angle block-why-we__block-angle--right">
            <div class="block-why-we__block-angle-line"></div>
          </div>
          <div class="block-why-we__block-title">
            Эффективность
          </div>
          <div class="block-why-we__block-text">
            Ваши клиенты будут довольны качеством ответов
          </div>
        </div>
      </div>
      <div class="block-why-we__row">
        <div class="block-why-we__block">
          <div class="block-why-we__block-angle block-why-we__block-angle--left">
            <div class="block-why-we__block-angle-line"></div>
          </div>
          <div class="block-why-we__block-angle block-why-we__block-angle--right">
            <div class="block-why-we__block-angle-line"></div>
          </div>
          <div class="block-why-we__block-title">
            автономность 
          </div>
          <div class="block-why-we__block-text">
            Чат-бот всегда на связи, даже ночью
          </div>
        </div>
        <div class="block-why-we__block">
          <div class="block-why-we__block-angle block-why-we__block-angle--left">
            <div class="block-why-we__block-angle-line"></div>
          </div>
          <div class="block-why-we__block-angle block-why-we__block-angle--right">
            <div class="block-why-we__block-angle-line"></div>
          </div>
          <div class="block-why-we__block-title">
            Разные режимы работы 
          </div>
          <div class="block-why-we__block-text">
            Выберете сами, какую роль будет выполнять ваш ИИ-бот
          </div>
        </div>
      </div>
      <div class="block-why-we__row">
        <div class="block-why-we__block block-why-we__block--hidden">
        </div>
        <div class="block-why-we__block">
          <div class="block-why-we__block-angle block-why-we__block-angle--left">
            <div class="block-why-we__block-angle-line"></div>
          </div>
          <div class="block-why-we__block-angle block-why-we__block-angle--right">
            <div class="block-why-we__block-angle-line"></div>
          </div>
          <div class="block-why-we__block-title">
            Идеальное знание продукта
          </div>
          <div class="block-why-we__block-text">
            Чат-бот ориентируется на базу знаний о вашем проекте. Бот не забудет важную информацию
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockWhyWe',
  data() {
    return {
      scrollSize: 0,
      scrollSize2: 0,
      isMobile: false,
      interval: null,
      // interval2: null,
    }
  },
  mounted() {
    this.checkMobile()
    window.addEventListener('resize', () => {
      this.checkMobile()
    }, true);
    this.reloadMarquee()
  },
  methods: {
    clearIntervals() {
      clearInterval(this.interval)
      // clearInterval(this.interval2)
      this.scrollSize = 0
      this.scrollSize2 = 0
    },
    checkMobile() {
      this.isMobile = document.documentElement.clientWidth <= 900
    },
    reloadMarquee() {
      this.clearIntervals()
      if (!this.isMobile) {
        this.interval = setInterval(() => {
          if (this.$refs.scrollText && this.$refs.scrollText.clientWidth && this.scrollSize >= this.$refs.scrollText.clientWidth) {
            this.scrollSize = 0
          }
          this.scrollSize += 2
        }, 10);
      } else {
        this.interval = setInterval(() => {
          if (this.$refs.scrollTextMobile && this.$refs.scrollTextMobile.clientWidth && this.scrollSize >= this.$refs.scrollTextMobile.clientWidth) {
            this.scrollSize = 0
          }
          this.scrollSize += 1
        }, 10);
        // this.interval2 = setInterval(() => {
        //   if (this.$refs.scrollTextMobile2 && this.$refs.scrollTextMobile2.clientWidth && this.scrollSize2 >= this.$refs.scrollTextMobile2.clientWidth) {
        //     this.scrollSize2 = 0
        //   }
        //   this.scrollSize2 += 2
        // }, 10);
      }
    }
  },
  computed: {
    animatedScrollText() {
      return `transform: translate(-${this.scrollSize}px, 0);`
    },
    // animatedScrollText2() {
    //   return `transform: translate(-${this.scrollSize2}px, 0);`
    // },
  },
  destroyed() {
    this.clearIntervals()
  },
  watch: {
    isMobile() {
      this.reloadMarquee()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index';
.block-why-we {
  width: 100%;

  &__marquees--mobile {
    max-width: 100%;
    margin-bottom: 0;
    font-family: 'LPM_PuffPuff', 'Bootshaus', "Roboto", "Arial", sans-serif;
    font-size: 50px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: left;
    color: #9DC9F9;
    overflow: hidden;
    display: none;
  }

  &__marquees {
    display: flex;
    max-width: 100%;
    margin-bottom: 41px;
    font-family: 'LPM_PuffPuff', 'Bootshaus', "Roboto", "Arial", sans-serif;
    font-size: 90px;
    font-weight: 400;
    line-height: 103px;
    letter-spacing: 0em;
    text-align: left;
    color: #9DC9F9;
    overflow: hidden;
  }
  &__marquee {
    text-align: left;
    color: #9DC9F9;
    text-transform: uppercase;
    white-space: nowrap;
    padding-right: 40px;

    &--dark {
      color: #26376D;
    }
  }

  &__rows {
    max-width: 1550px;
    margin: 0 auto;
  }

  &__row {
    display: flex;
    align-items: center;
    padding: 0 90px;
    margin-bottom: 20px;
    gap: 20px;

    &:nth-child(even) {
      justify-content: flex-start;
    }
    &:nth-child(odd) {
      justify-content: flex-end;
    }
  }

  &__block {
    border: 1px solid #9DC9F9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 30px;
    border-radius: 5px;
    position: relative;
    width: 500px;
    height: 230px;

    &--hidden {
      opacity: 0;
    }

    &-title {
      font-family: 'Bootshaus';
      font-size: 40px;
      font-weight: 400;
      line-height: 40px;
      text-align: left;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    &-text {
      font-size: 20px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }

    &-angle {
      width: 20px;
      height: 20px;
      background-color: #081435;
      position: absolute;

      &--left {
        top: -1px;
        left: -1px;

        .block-why-we__block-angle-line {
          width: 1px;
          height: 27px;
          background: #9DC9F9;
          transform: rotate(45deg) translate(5px, -9px);
        }
      }

      &--right {
        bottom: -1px;
        right: -1px;

        .block-why-we__block-angle-line {
          width: 1px;
          height: 27px;
          background: #9DC9F9;
          transform: rotate(45deg) translate(4px, -9px);
        }
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .block-why-we {
    &__row {
      &:nth-child(even) {
        justify-content: space-around;
      }
      &:nth-child(odd) {
        justify-content: space-around;
      }
      &:last-child {
        justify-content: center;
      }
    }
    &__block {
      &--hidden {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .block-why-we {
    &__row {
      padding: 0 20px;
      flex-direction: column;
      margin: 20px 0;
    }

    &__block {
      width: 100%;
      height: auto;
      box-sizing: border-box;
      padding: 12px 20px;

      &-title {
        font-size: 30px;
        line-height: 30px;
      }

      &-text {
        font-size: 18px;
        line-height: 18px;
      }
    }

    &__marquees {
      display: none;
    }

    &__marquees--mobile {
      display: flex;
    }

    &__marquee {
      padding-right: 20px;
    }
  }
}
</style>

