<template>
  <div id="app">
    <AppHeader @scroll="scrollToBlock($event)"/>
    <div class="block">
      <BlockMain />
    </div>
    <div class="block block-dark" ref="WhyWe">
      <BlockWhyWe />
    </div>
    <div class="block" ref="WorkMode">
      <BlockWorkMode />
    </div>
    <div class="block block-dark" ref="Tariff">
      <BlockTariff @scroll="scrollToBlock($event)"/>
    </div>
    <div class="block" ref="FAQ">
      <BlockFAQ />
    </div>
    <div class="block block-dark" ref="Form">
      <BlockForm />
    </div>
    <AppFooter @scroll="scrollToBlock($event)" />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader.vue'
import BlockMain from '@/components/blocks/BlockMain.vue'
import BlockWhyWe from '@/components/blocks/BlockWhyWe.vue'
import BlockWorkMode from '@/components/blocks/BlockWorkMode.vue'
import BlockTariff from '@/components/blocks/BlockTariff.vue'
import BlockFAQ from '@/components/blocks/BlockFAQ.vue'
import BlockForm from '@/components/blocks/BlockForm.vue'
import AppFooter from '@/components/AppFooter.vue'

export default {
  name: 'App',
  components: {
    AppHeader,
    BlockMain,
    BlockWhyWe,
    BlockWorkMode,
    BlockTariff,
    BlockFAQ,
    BlockForm,
    AppFooter,
  },
  methods: {
    scrollToBlock(blockName) {
      this.$refs[blockName]?.scrollIntoView({behavior: 'smooth'})
    }
  },
}
</script>

<style lang="scss" scoped>
#app {
  width: 100%;
  background-color: #cae4ff;
}

.block {
  padding: 60px 0;

  &-dark {
    border-radius: 40px;
    background-color: #081435;
    color: #9DC9F9;
    display: flex;
    justify-content: center;
    padding: 90px 0;
  }
}

@media screen and (max-width: 900px) {
  .block {
    padding: 40px 0;
  }
}
</style>

<style lang="scss">
body {
  margin: 0;
  font-family: "Roboto","Arial",sans-serif;
}

button, a {
  margin: 0;
  font-family: "Bootshaus", "Roboto", "Arial", sans-serif;
}

@font-face {
  font-family: 'LPM_PuffPuff';
  src: url('./assets/fonts/LPM_PuffPuff/LPM_PuffPuff.ttf')  format('truetype')
}

@font-face {
  font-family: 'Bootshaus';
  src: url('./assets/fonts/Bootshaus/Bootshaus.ttf')  format('truetype')
}

@font-face {
  font-family: 'Berniershade';
  src: url('./assets/fonts/Berniershade/Berniershade.ttf')  format('truetype')
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf')  format('truetype')
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url('./assets/fonts/Roboto/Roboto-Medium.ttf')  format('truetype')
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: url('./assets/fonts/Roboto/Roboto-Bold.ttf')  format('truetype')
}
</style>
