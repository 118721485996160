<template>
  <div
    class="app-button-container"
    :class="buttonClass"
  >
    <div class="app-button__angle app-button__angle--left">
      <div class="app-button__angle-line" v-if="type === 'white' || type === 'white-2'"></div>
    </div>
    <!-- <button
      class="app-button"
      @focus="isHover = true"
      @blur="isHover = false"
    >
      {{ label }}
    </button> -->
    <button
      class="app-button"
    >
      {{ label }}
    </button>
    <div class="app-button__angle app-button__angle--right">
      <div class="app-button__angle-line" v-if="type === 'white' || type === 'white-2'"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'black',
    },
  },
  data() {
    return {
      isHover: false
    }
  },
  computed: {
    buttonClass() {
      let classes = `app-button-container-${this.type} `
      classes += this.isHover ? 'hover' : ''
      return classes
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index';
.app-button-container {
  cursor: pointer;
  display: flex;
  position: relative;
  transition: all .2s;

  &:hover {
    transform: translateY(-4px);
    transition: all .2s;
  }

  &.hover {
    transform: translateY(-4px);
    transition: all .2s;
  }

  &-black {
    box-shadow: -11px 0 17px -23px #081435;
    &:hover {
      box-shadow: -11px 28px 17px -23px #081435;
    }
    &.hover {
      box-shadow: -11px 28px 17px -23px #081435;
    }

    .app-button {
      background-color: #081435;
      color: #9DC9F9;
    }
    .app-button__angle {
      border-left-color: #cae4ff;
      border-top-color: #cae4ff;
    }
  }

  &-white {
    box-shadow: -11px 0 17px -23px #9DC9F9;
    &:hover {
      box-shadow: -11px 26px 17px -23px #9DC9F9;
    }
    &.hover {
      box-shadow: -11px 26px 17px -23px #9DC9F9;
    }
    
    .app-button {
      background-color: #081435;
      border: 1px solid #9DC9F9;
      color: #9DC9F9;
    }
    .app-button__angle {
      border-left-color: #071029;
      border-top-color: #071029;
    }
  }

  &-white-2 {
    box-shadow: -11px 0 17px -23px #9DC9F9;
    &:hover {
      box-shadow: -11px 26px 17px -23px #9DC9F9;
    }
    &.hover {
      box-shadow: -11px 26px 17px -23px #9DC9F9;
    }

    .app-button {
      background-color: #081435;
      border: 1px solid #9DC9F9;
      color: #9DC9F9;
    }
    .app-button__angle {
      border-left-color: #081435;
      border-top-color: #081435;
    }
  }
}

.app-button__angle {
  border: 12px solid transparent;
  position: absolute;

  &--left {
    top: -1px;
    left: -1px;
  }

  &--right {
    bottom: -1px;
    right: -1px;
    transform: rotate(180deg);
  }

  &-line {
    width: 1px;
    height: 33px;
    background: #9DC9F9;
    transform: rotate(45deg) translate(-12px, -11px);
    position: absolute;
  }
}

.app-button {
  padding: 24px 0;
  border: none;
  outline: none;
  font-size: 18px;
  cursor: pointer;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  border-radius: 5px;
  font-size: 24px;
}
</style>