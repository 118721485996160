<template>
  <div class="input-template">
    <input
      type="text"
      :placeholder="label"
      v-model="text"
      class="input"
    >
    <div class="input-error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'AppInput',
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      text: ''
    }
  },
  watch: {
    value: {
      handler() {
        if (this.text !== this.value) {
          this.text = this.value
        }
      },
      immediate: true,
      deep: true,
    },
    text() {
      this.$emit('update', this.text)
    }
  },
}
</script>

<style lang="scss" scoped>
  .input-template {
    position: relative;
  }
  .input {
    border: 1px solid #9DC9F9;
    padding: 15px 20px;
    color: #9DC9F9;
    outline: none;
    background-color: #071029;
    width: 100%;
    box-sizing: border-box;

    &::placeholder {
      color: rgb(157, 201, 249, 0.3);
    }

    &:focus, &:focus-visible, &:focus-within {
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
    }
  }
  .input-error {
    position: absolute;
    font-size: 14px;
    line-height: 20px;
    top: 100%;
    left: 20px;
    color: #ee7b01;
  }
</style>