<template>
  <div class="block-main">
    <h2 class="block-main__title">
      Чат-бот на основе ИИ
      <br>-
      <span class="block-main__title--black">ваше конкурентное преимущество</span>
    </h2>
    <div class="block-main__subtitle">
      ИИ отвечает на все вопросы самостоятельно на основе вашей базы знаний, которую можно дополнять. Это просто
    </div>
    <AppButton class="block-main__btn" label="открыть чат" @click.native="openBot()"/> 
  </div>
</template>

<script>
import AppButton from '@/components/ui/AppButton.vue'

export default {
  name: 'BlockMain',
  components: {
    AppButton,
  },
  methods: {
    openBot() {
      if (document.querySelector('.btn-widget')) {
        document.querySelector('.btn-widget').click()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index';

.block-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  max-width: 80%;
  margin: 0 auto;

  &__title {
    font-family: Bootshaus;
    text-align: center;
    color: #62ADFF;
    // color: #9DC9F9;
    font-weight: 400;
    font-size: 94px;
    line-height: 84px;
    margin: 50px 0 0 0;
    text-transform: uppercase;

    &--black {
      color: #071029;
    }
  }

  &__subtitle {
    color: #071029;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    width: 400px;
    text-align: center;
  }

  &__btn {
    width: 400px;
  }
}

@media screen and (max-width: 900px) {
  .block-main {
    max-width: 90%;
    gap: 26px;

    &__title {
      font-size: 64px;
      line-height: 52px;
      margin-top: 20px;
    }

    &__subtitle {
      font-size: 16px;
      line-height: 20px;
      max-width: 90%;
    }

    &__btn {
      width: 90%;
    }
  }
}
</style>

