<template>
  <div class="block-form">
    <h2 class="block-form__title">{{ showFinishSlide ? 'Спасибо!' : 'связаться с нами' }}</h2>
    <form
      class="block-form__form"
      @submit.prevent="sendInfo()"
      v-if="!showFinishSlide"
    >
      <AppInput
        label="Имя*"
        :value="clientInfo.name"
        :error="errors.name"
        @update="clientInfo.name = $event;checkForm('name')"
      />
      <AppPhone
        :error="errors.phone"
        @update="clientInfo.phone = $event;checkForm('phone')"
      />
      <AppInput
        label="E-mail*"
        :value="clientInfo.email"
        :error="errors.email"
        @update="clientInfo.email = $event;checkForm('email')"
      />
      <AppInput
        label="Telegram*"
        :value="clientInfo.telegram"
        :error="errors.telegram"
        @update="clientInfo.telegram = $event;checkForm('telegram')"
      />
      <AppButton
        :label="sendingInfo ? loadingText : 'отправить'"
        type="white-2"
      /> 
    </form>
    <div v-else class="block-form__success">
      <div class="block-form__success-message">
        Менеджер свяжется с вами в ближайшее время 
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/ui/AppButton.vue'
import AppInput from '@/components/ui/AppInput.vue'
import AppPhone from '@/components/ui/AppPhone.vue'
import axios from 'axios'
export default {
  name: 'BlockForm',
  components: {
    AppButton,
    AppInput,
    AppPhone,
  },
  data() {
    return {
      errors: {
        name: '',
        phone: '',
        email: '',
        telegram: '',
        count: 0,
      },
      clientInfo: {
        name: '',
        phone: '',
        email: '',
        telegram: '',
      },
      sendingInfo: false,
      showFinishSlide: false,
      intervalAnimate: null,
      loadingText: 'Отправляем',
    }
  },
  beforeDestroy() {
    if (this.intervalAnimate) {
      clearInterval(this.intervalAnimate)
    }
  },
  methods: {
    validateEmail() {
      const re = /\S+@\S+\.\S+/;
      if (!re.test(this.clientInfo.email)) {
        this.errors.email = 'Введите корректный email'
        this.errors.count++
      } else {
        this.errors.email = ''
      }
    },
    validateName() {
      if (!this.clientInfo.name) {
        this.errors.name = 'Введите ваше имя'
        this.errors.count++
      } else {
        this.errors.name = ''
      }
    },
    validatePhone() {
      if (this.clientInfo.phone.replace(/[^\d]/g, '').length !== 11) {
        this.errors.phone = 'Введите корректный телефон'
        this.errors.count++
      } else {
        this.errors.phone = ''
      }
    },
    validateTelegram() {
      if (!this.clientInfo.telegram || this.clientInfo.telegram.length > 40) {
        this.errors.telegram = 'Введите корректный telegram'
        this.errors.count++
      } else {
        this.errors.telegram = ''
      }
    },
    checkForm(field = 'all') {
      switch (field) {
        case 'name':
          this.validateName()
          break;
        case 'email':
          this.validateEmail()
          if (!this.clientInfo.phone) {
            this.errors.phone = ''
          }
          if (!this.clientInfo.telegram) {
            this.errors.telegram = ''
          }
          break;
        case 'phone':
          this.validatePhone()
          if (!this.clientInfo.email) {
            this.errors.email = ''
          }
          if (!this.clientInfo.telegram) {
            this.errors.telegram = ''
          }
          break;
        case 'telegram':
          this.validateTelegram()
          if (!this.clientInfo.phone) {
            this.errors.phone = ''
          }
          if (!this.clientInfo.email) {
            this.errors.email = ''
          }
          break;
        case 'all':
          this.validateName()
          if (!this.clientInfo.telegram && !this.clientInfo.phone && !this.clientInfo.email) {
            this.errors.telegram = 'Введите или контактный telegram'
            this.errors.phone = 'Введите или контактный телефон'
            this.errors.email = 'Введите или контактный email'
            this.errors.count++
          } else {
            if (this.clientInfo.telegram) {
              this.validateTelegram()
            } 
            if (this.clientInfo.phone) {
              this.validatePhone()
            } 
            if (this.clientInfo.email) {
              this.validateEmail()
            }
          }
          break;
      }
    },
    async sendInfo() {
      this.errors.count = 0
      this.checkForm()
      if (this.errors.count || this.sendingInfo) {
        return
      }
      this.sendingInfo = true
      this.intervalAnimate = setInterval(() => {
        if (this.loadingText === 'Отправляем...') {
          this.loadingText = 'Отправляем'
        } else {
          this.loadingText += '.'
        }
      }, 500);
      const baseUrl = process.env.VUE_APP_DOMAIN
      try {
        await axios.post(`${baseUrl}/api/contact`, this.clientInfo)
        this.showFinishSlide = true
      } catch (error) {
        console.log('error', error);
        this.errors.name = error
        this.errors.count++
      }
      this.sendingInfo = false
      clearInterval(this.intervalAnimate)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index';
.block-form { 
  &__title {
    font-family: Bootshaus;
    text-align: center;
    font-weight: 400;
    font-size: 78px;
    line-height: 76px;
    color: #9DC9F9;
    margin-top: 0;
    margin-bottom: 40px;
    text-transform: uppercase;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__success {
    font-family: Bootshaus;
    font-size: 46px;
    font-weight: 400;
    line-height: 46px;
    text-align: center;
    margin-bottom: 20px;

    &-message {
      font-size: 36px;
    }
  }
}

@media screen and (max-width: 900px) {
  .block-form {
    width: 100%;

    &__title {
      font-size: 60px;
      line-height: 52px;
      margin-bottom: 20px;
    }

    &__form {
      padding: 0 20px;
    }
  }
}
</style>
