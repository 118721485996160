<template>
  <div class="fake-chat">
    <div
      v-for="message in showMessages"
      :key="message.id"
      class="row"
      :class="message.author === 'bot' ? 'row--reverse' : ''"
    >
      {{ message.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FakeChat',
  props: {
    messages: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      showMessages: [],
      currentMessage: null,
      timeout: null
    }
  },
  methods: {
    async printWord() {
      if (!this.showMessages[this.showMessages.length - 1]) {
        this.showMessage()
        return
      }
      const lastPrintText = this.showMessages[this.showMessages.length - 1].text || ''
      if (this.currentMessage?.text && this.currentMessage?.text.length > lastPrintText.length) {
        this.showMessages[this.showMessages.length - 1].text = this.currentMessage.text.slice(0, lastPrintText.length + 1)
        this.timeout = setTimeout(() => {
          this.printWord()
        }, 50);
      } else {
        this.currentMessage = null
        this.timeout = setTimeout(() => {
          this.showMessage()
        }, 200);
      }
    },
    showMessage() {
      if (this.currentMessage) {
        this.timeout = setTimeout(() => {
          this.printWord()
        }, 50);
      } else {
        if (this.messages > this.showMessages) {
          const currentNumberMessage = this.showMessages.length
          if (this.messages[currentNumberMessage]) {
            this.currentMessage = this.messages[currentNumberMessage]
            this.showMessages.push({
              id: this.messages[currentNumberMessage]?.id,
              author: this.messages[currentNumberMessage]?.author,
              text: ''
            })
            this.timeout = setTimeout(() => {
              this.printWord()
            }, 50);
          } else {
            this.timeout = setTimeout(() => {
              this.clearWords()
            }, 5000);
          }
        } else {
          this.timeout = setTimeout(() => {
            this.clearWords()
          }, 5000);
        }
      }
    },
    clearWords() {
      if (this.currentMessage) {
        if (this.currentMessage.text) {
          this.currentMessage.text = this.currentMessage.text.slice(0, -1)
          setTimeout(() => {
            this.clearWords()
          }, 10);
        } else {
          this.currentMessage = null
          this.showMessages.pop()
          setTimeout(() => {
            this.clearWords()
          }, 10);
        }
      } else {
        const currentNumberMessage = this.showMessages.length
        if (currentNumberMessage) {
          this.currentMessage = this.showMessages[currentNumberMessage - 1]
          this.clearWords()
        } else {
          setTimeout(() => {
            this.showMessage()
          }, 500);
        }
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
    this.showMessages = []
    this.currentMessage = null
  },
  mounted() {
    this.showMessage()
  }
}
</script>

<style lang="scss" scoped>
.fake-chat {
  width: 100%;
  height: 400px;

  .row {
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #9dc9f9;
    padding: 10px;
    max-width: calc(100% - 150px);
    width: fit-content;

    &.row--reverse {
      text-align: end;
      margin-left: auto;
    }
  }
}

@media screen and (max-width: 1450px) {
  .fake-chat {
    height: 450px;

    .row {
      max-width: calc(100% - 50px);
    }
  }
}

@media screen and (max-width: 1100px) {
  .fake-chat {
    height: 300px;
  }
}

@media screen and (max-width: 900px) {
  .fake-chat {
    height: 300px;
  }
}

@media screen and (max-width: 650px) {
  .fake-chat {
    height: 380px;
  }
}

@media screen and (max-width: 500px) {
  .fake-chat {
    height: 300px;
    .row {
      max-width: calc(100% - 50px);
      padding: 4px 6px;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
</style>