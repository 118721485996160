var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-tariff"},[_c('h2',{staticClass:"block-tariff__title"},[_vm._v("тарифы")]),_c('div',{staticClass:"block-tariff__tariffs"},[_c('div',{staticClass:"tariff"},[_vm._m(0),_c('AppButton',{attrs:{"label":"узнать больше","type":"white-2"},nativeOn:{"click":function($event){return _vm.openForm()}}}),_vm._m(1),_vm._m(2)],1),_c('div',{staticClass:"tariff"},[_vm._m(3),_c('AppButton',{attrs:{"label":"узнать больше","type":"white-2"},nativeOn:{"click":function($event){return _vm.openForm()}}}),_vm._m(4),_vm._m(5)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticClass:"tariff__title"},[_vm._v("Базовый")]),_c('div',{staticClass:"tariff__list-item"},[_c('img',{staticClass:"list-item__img",attrs:{"src":"images/check.svg","alt":"icon list item"}}),_vm._v(" 500 запросов к чат-боту ")]),_c('div',{staticClass:"tariff__list-item"},[_c('img',{staticClass:"list-item__img",attrs:{"src":"images/check.svg","alt":"icon list item"}}),_vm._v(" обучение по базе знаний ")]),_c('div',{staticClass:"tariff__list-item"},[_c('img',{staticClass:"list-item__img",attrs:{"src":"images/check.svg","alt":"icon list item"}}),_vm._v(" выбор режима чат-бота ")]),_c('div',{staticClass:"tariff__list-item"},[_c('img',{staticClass:"list-item__img",attrs:{"src":"images/check.svg","alt":"icon list item"}}),_vm._v(" бесплатный пробный период ")]),_c('div',{staticClass:"tariff__list-item"},[_c('img',{staticClass:"list-item__img",attrs:{"src":"images/check.svg","alt":"icon list item"}}),_vm._v(" уникальный дизайн под ваш стиль ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tariff__block-angle tariff__block-angle--left"},[_c('div',{staticClass:"tariff__block-angle-line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tariff__block-angle tariff__block-angle--right"},[_c('div',{staticClass:"tariff__block-angle-line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',{staticClass:"tariff__title"},[_vm._v("Премиум")]),_c('div',{staticClass:"tariff__list-item"},[_c('img',{staticClass:"list-item__img",attrs:{"src":"images/check.svg","alt":"icon list item"}}),_vm._v(" гибкая настройка количества запросов ")]),_c('div',{staticClass:"tariff__list-item"},[_c('img',{staticClass:"list-item__img",attrs:{"src":"images/check.svg","alt":"icon list item"}}),_vm._v(" обучение по базе знаний ")]),_c('div',{staticClass:"tariff__list-item"},[_c('img',{staticClass:"list-item__img",attrs:{"src":"images/check.svg","alt":"icon list item"}}),_vm._v(" выбор режима чат-бота ")]),_c('div',{staticClass:"tariff__list-item"},[_c('img',{staticClass:"list-item__img",attrs:{"src":"images/check.svg","alt":"icon list item"}}),_vm._v(" бесплатный пробный период ")]),_c('div',{staticClass:"tariff__list-item"},[_c('img',{staticClass:"list-item__img",attrs:{"src":"images/check.svg","alt":"icon list item"}}),_vm._v(" уникальный дизайн под ваш стиль ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tariff__block-angle tariff__block-angle--left"},[_c('div',{staticClass:"tariff__block-angle-line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tariff__block-angle tariff__block-angle--right"},[_c('div',{staticClass:"tariff__block-angle-line"})])
}]

export { render, staticRenderFns }