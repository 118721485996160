<template>
  <div class="block-tariff">
    <h2 class="block-tariff__title">тарифы</h2>
    <div class="block-tariff__tariffs">
      <div class="tariff">
        <div>
          <h2 class="tariff__title">Базовый</h2>
          <div class="tariff__list-item">
            <img class="list-item__img" src="images/check.svg" alt="icon list item">
            500 запросов к чат-боту
          </div>
          <div class="tariff__list-item">
            <img class="list-item__img" src="images/check.svg" alt="icon list item">
            обучение по базе знаний
          </div>
          <div class="tariff__list-item">
            <img class="list-item__img" src="images/check.svg" alt="icon list item">
            выбор режима чат-бота
          </div>
          <div class="tariff__list-item">
            <img class="list-item__img" src="images/check.svg" alt="icon list item">
            бесплатный пробный период
          </div>
          <div class="tariff__list-item">
            <img class="list-item__img" src="images/check.svg" alt="icon list item">
            уникальный дизайн под ваш стиль
          </div>
        </div>

        <AppButton label="узнать больше" type="white-2" @click.native="openForm()"/> 
        <div class="tariff__block-angle tariff__block-angle--left">
          <div class="tariff__block-angle-line"></div>
        </div>
        <div class="tariff__block-angle tariff__block-angle--right">
          <div class="tariff__block-angle-line"></div>
        </div>
      </div>

      <div class="tariff">
        <div>
          <h2 class="tariff__title">Премиум</h2>
          <div class="tariff__list-item">
            <img class="list-item__img" src="images/check.svg" alt="icon list item">
            гибкая настройка количества запросов
          </div>
          <div class="tariff__list-item">
            <img class="list-item__img" src="images/check.svg" alt="icon list item">
            обучение по базе знаний
          </div>
          <div class="tariff__list-item">
            <img class="list-item__img" src="images/check.svg" alt="icon list item">
            выбор режима чат-бота
          </div>
          <div class="tariff__list-item">
            <img class="list-item__img" src="images/check.svg" alt="icon list item">
            бесплатный пробный период
          </div>
          <div class="tariff__list-item">
            <img class="list-item__img" src="images/check.svg" alt="icon list item">
            уникальный дизайн под ваш стиль
          </div>
        </div>

        <AppButton label="узнать больше" type="white-2" @click.native="openForm()"/> 
        <div class="tariff__block-angle tariff__block-angle--left">
          <div class="tariff__block-angle-line"></div>
        </div>
        <div class="tariff__block-angle tariff__block-angle--right">
          <div class="tariff__block-angle-line"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/ui/AppButton.vue'
export default {
  name: 'BlockTariff',
  components: {
    AppButton,
  },
  methods: {
    openForm() {
      this.$emit('scroll', 'Form')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index';
.block-tariff { 
  &__title {
    font-family: Bootshaus;
    text-align: center;
    font-weight: 400;
    font-size: 78px;
    line-height: 76px;
    color: #9DC9F9;
    margin-top: 0;
    margin-bottom: 40px;
    text-transform: uppercase;
  }

  &__tariffs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    .tariff {
      border: 1px solid #9DC9F9;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 30px;
      border-radius: 5px;
      position: relative;
      height: 350px;
      width: 400px;

      &__title {
        font-size: 27px;
        font-weight: 500;
        line-height: 34px;
        text-align: left;
        margin: 0 0 20px 0;
        text-transform: uppercase;
      }
      
      &__list-item {
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        .list-item__img {
          width: 14px;
          height: 14px;
          margin-right: 8px;
        }
      }


      &__block-angle {
        width: 20px;
        height: 20px;
        background-color: #081435;
        position: absolute;

        &--left {
          top: -1px;
          left: -1px
        }

        &--right {
          bottom: -1px;
          right: -1px;
        }

        &-line {
          width: 1px;
          height: 27px;
          background: #9DC9F9;
          transform: rotate(45deg) translate(5px, -9px);
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .block-tariff {
    &__tariffs {
      .tariff {
        height: 290px;
        width: 300px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .block-tariff {
    width: 100%;

    &__title {
      font-size: 60px;
      line-height: 52px;
      margin-bottom: 20px;
    }

    &__tariffs {
      flex-direction: column;
      padding: 0 20px;

      .tariff {
        width: 100%;
        height: auto;
        box-sizing: border-box;
        gap: 20px;
        padding: 20px;

        &__title {
          font-size: 24px;
          line-height: 28px;
          margin: 0 0 12px 0;
        }

        &__list-item {
          font-size: 16px;
          line-height: 18px;

          .list-item__img {
            width: 14px;
            height: 14px;
            margin-right: 4px;
          }
        }
      }
    }
  }
}
</style>
