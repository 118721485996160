<template>
  <div class="block-work-mode">
    <h2 class="block-work-mode__title">
      разные режимы работы
    </h2>
    <div class="block-work-mode__header">
      <div
        class="block-work-mode__header-item"
        :class="{'block-work-mode__header-item--selected': selectedWorkMode === 'consultant'}"
        @click="selectedWorkMode = 'consultant'"
      >
        <div class="block-work-mode__header-item-angle"></div>
        <span class="header-item__text">Консультант</span>
        <img class="header-item__img" src="images/consultant.svg" alt="icon list item">
      </div>
      <div
        class="block-work-mode__header-item"
        :class="{'block-work-mode__header-item--selected': selectedWorkMode === 'support'}"
        @click="selectedWorkMode = 'support'"
      >
        <div class="block-work-mode__header-item-angle"></div>
        <span class="header-item__text">Служба поддержки</span>
        <img class="header-item__img" src="images/support.svg" alt="icon list item">
      </div>
    </div>
    <div class="block-work-mode__body">
      <div class="block-work-mode__body-slide" v-if="selectedWorkMode === 'support'">
        <FakeChat :messages="messagesSupport" key="support"/>
        <div>
          <h2>// ИИ чат-бот поможет разобраться с проблемой</h2>
          <div>Быстро и точно определит проблему и предложит наиболее эффективное решение</div>
        </div>
      </div>
      <div class="block-work-mode__body-slide" v-if="selectedWorkMode === 'consultant'">
        <FakeChat :messages="messagesConsultant" key="consultant"/>
        <div>
          <h2>// Режим Консультант поможет определиться с выбором</h2>
          <div>ИИ отвечает на все вопросы самостоятельно на основе вашей базы знаний, которую можно дополнять. Это просто</div>
          <AppButton class="block-work-mode__body-slide-btn" label="попробовать" type="white" @click.native="openBot()"/> 
        </div>
      </div>
      <div class="block-work-mode__body--angle-right"></div>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/ui/AppButton.vue'
import FakeChat from '@/components/ui/FakeChat.vue'

export default {
  name: 'BlockWorkMode',
  components: {
    AppButton,
    FakeChat,
  },
  data() {
    return {
      selectedWorkMode: 'consultant',
      messagesConsultant: [
        {
          id: 1,
          author: 'people',
          text: 'Мне нужна помощь'
        },
        {
          id: 2,
          author: 'bot',
          text: 'Рад приветствовать вас на нашем сайте!'
        },
        {
          id: 3,
          author: 'bot',
          text: 'Я AI помощник. Чем могу быть полезен?'
        },
        {
          id: 4,
          author: 'people',
          text: 'Ищу подарок для любимого человека'
        },
        {
          id: 5,
          author: 'bot',
          text: 'Наш магазин готов предложить вам следующие товары...'
        },
      ],
      messagesSupport: [
        {
          id: 1,
          author: 'people',
          text: 'У меня возникла проблема с оплатой'
        },
        {
          id: 2,
          author: 'bot',
          text: 'Здравствуйте! Пожалуйста, предоставьте текст или код ошибки'
        },
        {
          id: 3,
          author: 'people',
          text: 'Код ошибки - 1053667'
        },
        {
          id: 4,
          author: 'bot',
          text: 'Приносим свои извинения за предоставленные неудобства. В данный момент происходит обновление сервиса. Попробуйте, пожалуйста, повторить операцию через пару минут.'
        },
        {
          id: 5,
          author: 'people',
          text: 'Спасибо, помогло'
        },
      ],
    }
  },
  methods: {
    openBot() {
      if (document.querySelector('.btn-widget')) {
        document.querySelector('.btn-widget').click()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index';

.block-work-mode {
  width: 80%;
  margin: auto;

  &__title {
    font-family: Bootshaus;
    text-align: center;
    font-weight: 400;
    font-size: 78px;
    line-height: 76px;
    text-transform: uppercase;
    color: #1B2C63;
    margin-bottom: 40px;
    margin-top: 0;
  }

  &__header {
    display: grid;
    grid-template-columns: 50% 50%;

    &-item {
      font-family: "Bootshaus", "Roboto", "Arial", sans-serif;
      background-color: #071029;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 64px;
      border-radius: 3px;
      font-size: 24px;
      font-weight: 400;
      line-height: 16px;
      text-align: center;
      color: #9DC9F9;
      cursor: pointer;
      position: relative;

      .header-item__img {
        display: none;
        width: 40px;
        height: 40px;
      }

      &--selected {
        background-color: #9DC9F9;
        color: #071029;
      }

      &-angle {
        position: absolute;
        top: 0;
        left: 0;
        border: 32px solid transparent;
        border-left-color: #cae4ff;
        border-top-color: #cae4ff;
      }
    }
  }

  &__body {
    background-color: #071029;
    border-top: 1px solid #9DC9F9;
    border-radius: 3px;
    padding: 40px 60px;
    position: relative;

    &-slide {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 60px;
      align-items: center;
      color: #9DC9F9;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;

      &-btn {
        margin-top: 35px;
      }

      h2 {
        font-size: 48px;
        line-height: 45px;
        font-weight: 400;
        text-align: left;
        margin: 15px 0;
        font-family: 'Bootshaus';
      }
    }

    &--angle-right {
      border: 24px solid transparent;
      position: absolute;
      bottom: 0;
      right: 0;
      border-right-color: #cae4ff;
      border-bottom-color: #cae4ff;
    }
  }
}

@media screen and (max-width: 1100px) {
  .block-work-mode {
    &__body {
      &-slide {
        grid-template-columns: 1fr;
        gap: 30px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .block-work-mode {
    &__title {
      font-size: 60px;
      line-height: 52px;
      margin-bottom: 20px;
    }

    &__header {
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 48px;
        padding-left: 16px;
        box-sizing: border-box;
        
        &-angle {
          border: 16px solid transparent;
          border-left-color: #cae4ff;
          border-top-color: #cae4ff;
        }

        .header-item {
          &__text {
            display: none;
          }
          &__img {
            display: block;
          }
        }
      }
    }

    &__body {
      padding: 12px 20px;

      &-slide {
        font-size: 18px;
        line-height: 18px;

        h2 {
          font-size: 30px;
          line-height: 28px;
          margin: 10px 0;
        }

        &-btn {
          margin: 15px 0;
        }
      }
    }
  }
}
</style>

