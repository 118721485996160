<template>
  <div>
    <div class="app-header">
      <div class="app-header__logo">AI-TALKER</div>
      <a href="/" @click.prevent="$emit('scroll', 'WhyWe')">Почему именно мы?</a>
      <a href="/" @click.prevent="$emit('scroll', 'WorkMode')">Примеры работы</a>
      <a href="/" @click.prevent="$emit('scroll', 'Tariff')">Пакеты услуг</a>
      <a href="/" @click.prevent="$emit('scroll', 'FAQ')">Вопросы / Ответы</a>
    </div>
    <div class="app-header-mobile">
      <div class="app-header-mobile__icon" @click="showMenu = true">
        <div class="app-header-mobile__icon-line"></div>
        <div class="app-header-mobile__icon-line"></div>
        <div class="app-header-mobile__icon-line"></div>
      </div>
      <Transition name="slide">
        <div class="app-header-mobile__body" v-if="showMenu">
          <div class="app-header__logo">AI-TALKER</div>
          <a href="/" @click.prevent="selectLink('WhyWe')">Почему именно мы?</a>
          <a href="/" @click.prevent="selectLink('WorkMode')">Примеры работы</a>
          <a href="/" @click.prevent="selectLink('Tariff')">Пакеты услуг</a>
          <a href="/" @click.prevent="selectLink('FAQ')">Вопросы / Ответы</a>
          <div class="app-header-mobile__body-icon" @click="closeMenu()">
            <div class="app-header-mobile__body-icon-line1"></div>
            <div class="app-header-mobile__body-icon-line2"></div>
          </div>
        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppHeader',
  data() {
    return {
      showMenu: false,
    }
  },
  methods: {
    closeMenu() {
      this.showMenu = false
    },
    selectLink(blockName) {
      this.closeMenu()
      setTimeout(() => {
        this.$emit('scroll', blockName)
      }, 200)
    }
  },
  watch: {
    showMenu() {
      document.body.style = this.showMenu ? 'overflow: hidden;' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index';

.app-header {
  position: sticky;
  top: 0;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #cae4ff;
  gap: 60px;
  z-index: 1000;
  padding: 0 106px;
  box-sizing: border-box;

  &__logo {
    font-family: "Bootshaus", "Roboto", "Arial", sans-serif;
    font-size: 28px;
    padding: 2px 8px 0 8px;
    line-height: 18px;
    transform: translate(0, 2px);
    color: #081435;
    border-bottom: 1px solid #081435;
    white-space: nowrap;
  }

  a {
    color: #081435;
    font-weight: 400;
    text-decoration: none;
    font-size: 22px;

    &:hover, &:focus, &:focus-visible, &:focus-within {
      color: #5493d8;
      outline: none;
    }
  }
}

.app-header-mobile {
  display: none;

  &__icon {
    position: fixed;
    z-index: 1;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 20px;

    &-line {
      width: 100%;
      height: 2px;
      background-color: #081435;
      border: 1px solid #9DC9F9;
    }
  }

  &__body {
    position: fixed;
    z-index: 9999999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #cae4ff;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    box-sizing: border-box;

    &-icon {
      position: absolute;
      top: 20px;
      right: 10px;
      width: 20px;
      height: 20px;

      &-line1 {
        width: 20px;
        height: 1px;
        background-color: #081435;
        transform: rotate(45deg);
        position: absolute;
        top: 50%;
      }

      &-line2 {
        width: 20px;
        height: 1px;
        background-color: #081435;
        transform: rotate(-45deg);
        position: absolute;
        top: 50%;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 20px 20px;
  }
}

.slide-enter-active {
  animation: slide 0.5s;
}

.slide-leave-active {
  animation: slide 0.5s reverse;
}

@keyframes slide {
  0% {
    transform: translate(0, 1000px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@media screen and (max-width: 1250px) {
  .app-header {
    padding: 0 60px;
  }
}

@media screen and (max-width: 1150px) {
  .app-header {
    &__logo {
      font-size: 20px;
      line-height: 12px;
      padding-bottom: 3px;
    }
    a {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 800px) {
  .app-header {
    display: none;
  }

  .app-header-mobile {
    display: block;
  }

  .app-header__logo {
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
    width: max-content;
  }

  a {
    font-size: 20px;
    color: #081435;
  }
}
</style>

