<template>
  <div class="app-footer">
    <div class="app-footer__links">
      <a href="/" @click.prevent="$emit('scroll', 'WhyWe')">Почему именно мы?</a>
      <a href="/" @click.prevent="$emit('scroll', 'WorkMode')">Примеры работы</a>
      <a href="/" @click.prevent="$emit('scroll', 'Tariff')">Пакеты услуг</a>
      <a href="/" @click.prevent="$emit('scroll', 'FAQ')">Вопросы / Ответы</a>
    </div>
    <div class="app-footer__info">
      <!-- <div>AurorAI Corporated</div> -->
      <div> AI-TALKER. 2024г.</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index';

.app-footer {
  box-shadow: 0 6px 13px 0px #7d7c7c;
  padding: 20px 0;
  &__links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 12px;

    a {
      color: #626262;
      text-decoration: none;
      font-size: 24px;

      &:hover, &:focus, &:focus-visible, &:focus-within {
        color: #5493d8;
        outline: none;
      }
    }
  }
  &__info {
    font-family: "Bootshaus", "Roboto", "Arial", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    font-size: 20px;
  }
}


@media screen and (max-width: 900px) {
  .app-footer__links {
    display: none;
  }
}
</style>

