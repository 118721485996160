<template>
  <div class="input-template">
    <VueTelInput
      v-model="phone"
      :inputOptions="{
        placeholder:'Телефон*'
      }"
      validCharactersOnly
      @input="checkNumber"
    />
    <div class="input-error" v-if="errorText">{{ errorText }}</div>
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

export default {
  name: 'AppPhone',
  components: {
    VueTelInput,
  },
  props: {
    error: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      phone: '',
      errorText: '',
      isFirstValidate: 2,
    }
  },
  methods: {
    checkNumber(number, validationState) {
      if (this.isFirstValidate) {
        this.isFirstValidate--
        return
      }
      if (validationState && validationState.valid) {
        this.errorText = ''
        this.$emit('update', this.phone)
      } else {
        this.errorText = 'Введите корректный телефон'
      }
    }
  },
  watch: {
    error() {
      this.errorText = this.error
    },
  },
}
</script>

<style lang="scss" scoped>
  .input-template {
    position: relative;
  }
  .input-error {
    position: absolute;
    font-size: 14px;
    line-height: 20px;
    top: 100%;
    left: 20px;
    color: #ee7b01;
  }
  ::v-deep {
    .vti__dropdown {
      display: none;
    }
    .vti__input {
      border: 1px solid #9DC9F9;
      padding: 15px 20px;
      color: #9DC9F9;
      outline: none;
      background-color: #071029;
      width: 100%;
      box-sizing: border-box;
      border-radius: 0;

      &::placeholder {
        color: rgb(157, 201, 249, 0.3);
      }
    }
    .vue-tel-input {
      border: none;
      border-radius: 0;

      &:focus, &:focus-visible, &:focus-within {
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
      }
    }
  }
</style>