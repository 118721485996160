<template>
  <div class="block-faq">
    <h2 class="block-faq__title">вопрос-ответ</h2>
    <div class="block-faq__faqs">
      <div class="faq" v-for="faq in faqs" :key="faq.id">
        <div class="faq__question" @click="faq.show = !faq.show">
          {{ faq.question }}
          <div class="faq__question-icon">
            <div class="faq__question-icon-stick-horizontal"></div>
            <div class="faq__question-icon-stick-verticale" v-if="!faq.show"></div>
          </div>
          <div class="faq__question-angle"></div>
        </div>
        <div class="faq__answer" v-show="faq.show" v-html="faq.answer"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockTariff',
  data() {
    return {
      faqs: [
        {
          id: 1,
          question: 'Что такое Ai-Talker?',
          answer: 'Мы предоставляем удобный чат-бот на основе ИИ для вашего бизнеса.<br><br>Чат бот может отвечать на вопросы клиентов 24/7, работать в разных режимах: как продавец-консультант или как сотрудник службы поддержки.',
          show: false,
        },
        {
          id: 2,
          question: 'Как создать чат-бота?',
          answer: 'У нас есть удобный интерфейс для создания базы знаний. В любой момент ее можно дополнить. После настройки вы сможете проверить работу AI-TALKER',
          show: false,
        },
        {
          id: 3,
          question: 'Как внедрить чат-бота на свой сайт?',
          answer: 'Чат-бот можно встроить в любой проект при помощи пары строк кода. У нас есть простая инструкция, как можно это сделать.',
          show: false,
        },
        {
          id: 4,
          question: 'В чем отличие от Chat-GPT?',
          answer: 'Другие языковые модели не знают подробности про ваш проект. Наша модель настраивается специально под вас.',
          show: false,
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index';
.block-faq { 
  &__title {
    font-family: Bootshaus;
    text-align: center;
    font-weight: 400;
    font-size: 78px;
    line-height: 76px;
    color: #1B2C63;
    margin-top: 0;
    margin-bottom: 40px;
    text-transform: uppercase;
  }

  &__faqs {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .faq {
    width: 1200px;

      &__question {
        font-family: "Bootshaus", "Roboto", "Arial", sans-serif;
        background-color: #071029;
        width: 100%;
        box-sizing: border-box;
        border-top-left-radius: 5px;
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 30px;
        font-size: 27px;
        font-weight: 400;
        line-height: 36px;
        color: #9DC9F9;
        cursor: pointer;
        align-items: center;

        &-angle {
          position: absolute;
          top: 0;
          right: 0;
          border: 15px solid transparent;
          border-right-color: #cae4ff;
          border-top-color: #cae4ff;
        }

        &-icon {
          width: 42px;
          height: 42px;
          border-radius: 50%;
          background-color: #9DC9F9;
          position: relative;

          &-stick {
            &-horizontal {
              height: 1px;
              width: 17px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, 0);
              background-color: #071029;
            }

            &-verticale {
              height: 17px;
              width: 1px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(0, -50%);
              background-color: #071029;
            }
          }
        }
      }

      &__answer {
        border: 1px solid #081435;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: #9DC9F9;
        padding: 40px 30px;
        color: #071029;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
      }

    }
  }
}

@media screen and (max-width: 1450px) {
  .block-faq {
    &__faqs {
      .faq {
        width: 1000px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .block-faq {
    &__faqs {
      .faq {
        width: 800px;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .block-faq {
    &__title {
      font-size: 60px;
      line-height: 52px;
      margin-bottom: 20px;
    }

    &__faqs {
      padding: 0 20px;
      .faq {
        width: 100%;

        &__question {
          padding: 20px;
          font-size: 22px;
          line-height: 22px;
          align-items: center;

          &-icon {
            width: 26px;
            height: 26px;

            &-stick-horizontal {
              width: 12px;
            }

            &-stick-verticale {
              height: 12px;
            }
          }
        }

        &__answer {
          padding: 10px 10px;
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
